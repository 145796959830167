import React from "react";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "gatsby";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import coloredDots from "../images/fourdots.svg";

const Card = ({ cardData }) => {
  const { title, slug, excerpt, featuredImage } = cardData;
  const thumbnail = getImage(featuredImage?.node.localFile.childImageSharp);
  return (
    <>
      <Row className='pb-5' id='cards-block'>
        <Col xl={3} lg={3} md={4} sm={3} xs={12} className='bg-dark'>
          <Link to={`/${slug}`}>
            <GatsbyImage
              image={thumbnail}
              className='ws-card-block'
              alt={featuredImage?.node.altText}
            />
          </Link>
        </Col>
        <Col xl={9} lg={9} md={8} sm={9} xs={12}>
          <CardBlock>
            <CardTitle style={{ position: "relative" }}>
              <span>{title}</span>
              <div className='line003'></div>
              <br />
            </CardTitle>
            <CardArticle dangerouslySetInnerHTML={{ __html: excerpt }} />
            <CardFooter>
              <Dots src={coloredDots} alt='colored dots' />
              <Link to={`/${slug}`} className='text-decoration-none'>
                <span className='text-white'>Читать дальше</span>
              </Link>
            </CardFooter>
          </CardBlock>
        </Col>
      </Row>
    </>
  );
};

Card.propTypes = {
  cardData: PropTypes.object.isRequired,
};

export default Card;

const CardBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-color: white;
`;

const CardTitle = styled.div`
  padding: 1rem;
  font-size: 1.25rem;
  font-family: Oswald;
  font-weight: 500;
  background-color: white;
  padding-left: 50px;
  padding-right: 50px;
  height: 10px;
`;
const CardArticle = styled.article`
  padding: 1rem;
  background-color: white;
  padding-top: 3.5rem;
  line-height: 1.5rem;
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  padding-left: 50px;
  padding-right: 50px;
`;
const CardFooter = styled.div`
  position: relative;
  padding: 1rem;
  -webkit-transition: background-color 500ms ease;
  -moz-transition: background-color 500ms ease;
  -o-transition: background-color 500ms ease;
  transition: background-color 500ms ease;
  transition: background-color 0.5s ease;
  background-color: #1e2b37;
  font-family: Oswald;
  font-weight: 500;
  padding-left: 50px;
  padding-right: 50px;
  &:hover {
    -webkit-transition: background-color 500ms ease;
    -moz-transition: background-color 500ms ease;
    -o-transition: background-color 500ms ease;
    transition: background-color 500ms ease;
    background-color: #344352;
  }
`;
const Dots = styled.img`
  position: absolute;
  right: 5px;
  top: -14px;
  width: 170px;
  height: 30px;
`;
