exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-tag-wp-tag-slug-js": () => import("./../../../src/pages/tag/{WpTag.slug}.js" /* webpackChunkName: "component---src-pages-tag-wp-tag-slug-js" */),
  "component---src-pages-wp-post-slug-js": () => import("./../../../src/pages/{WpPost.slug}.js" /* webpackChunkName: "component---src-pages-wp-post-slug-js" */),
  "component---src-templates-article-post-js": () => import("./../../../src/templates/article-post.js" /* webpackChunkName: "component---src-templates-article-post-js" */),
  "component---src-templates-portfolio-post-js": () => import("./../../../src/templates/portfolio-post.js" /* webpackChunkName: "component---src-templates-portfolio-post-js" */)
}

