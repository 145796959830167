import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "../components/Card";
import HeaderUnderlined from "../components/HeaderUnderlined";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Meta from "../components/Meta";
import {
  AiOutlineLink,
  AiOutlineSetting,
  AiOutlineThunderbolt,
} from "react-icons/ai";
import Pagination from "../components/Pagination";

const WebProject = ({ data, pageContext }) => {
  const {
    title,
    content,
    featuredImage,
    cardServices,
    colorAccent,
    webProjects,
    tags,
  } = data.wpWebproject;
  const thumbnail = getImage(featuredImage?.node.localFile.childImageSharp);
  const bgColor = {
    backgroundColor: `${colorAccent.backgroundColor}`,
  };
  return (
    <Layout>
      <Container fluid='lg' id='portfolio-post'>
        <Row>
          <Col xl={3} lg={3} md={4} sm={3} xs={12} style={bgColor}>
            <div className='shadow-sm bg-secondary'>
              <GatsbyImage
                image={thumbnail}
                alt={featuredImage?.node.altText}
              />
            </div>
          </Col>
          <Col
            xl={9}
            lg={9}
            md={8}
            sm={9}
            xs={12}
            className='p-3 p-md-5 bg-white'
          >
            <HeaderUnderlined title={title} className='fadein' />
            {/* show web project description  */}
            {webProjects.webProjectBriefDescription && (
              <div className='p-3' style={bgColor}>
                <h3 className='text-center'>
                  {webProjects.webProjectBriefDescription}
                </h3>
              </div>
            )}
            {/* show web project link  */}
            {webProjects.webProjectLink && (
              <div className='p-2 border-bottom border-secondary border-opacity-25'>
                <span>
                  <AiOutlineLink className='react-icons mr-2 mb-1' />
                </span>
                <span className='fw-bold px-2'>Ссылка: </span>
                <span>
                  <a
                    href={webProjects.webProjectLink}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='fw-bold'
                  >
                    {webProjects.webProjectLink}
                  </a>
                </span>
              </div>
            )}
            {/* show web project platform */}
            {webProjects.webProjectPlatform && (
              <div className='p-2 border-bottom border-secondary border-opacity-25'>
                <span>
                  <AiOutlineThunderbolt className='react-icons mr-2' />
                </span>
                <span className='fw-bold px-2'>Платформа: </span>
                <span className='text-capitalize'>
                  {webProjects.webProjectPlatform}
                </span>
              </div>
            )}
            {/* show web project instruments */}
            {webProjects.webProjectInstruments && (
              <div className='p-2 border-bottom border-secondary border-opacity-25'>
                <span>
                  <AiOutlineSetting className='react-icons mr-2' />
                </span>
                <span className='fw-bold px-2'>Инструменты разработки: </span>
                <div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: webProjects.webProjectInstruments,
                    }}
                  />
                </div>
              </div>
            )}
            <div
              dangerouslySetInnerHTML={{
                __html: content,
              }}
              className='py-3'
            />
            <Pagination paginationData={pageContext} />

            <div className='tag-wrap'>
              {tags.nodes.map((tag, index) => (
                <div className='tag' key={index}>
                  <Link
                    to={tag.link}
                    className='btn btn-sm tag-button'
                    role='button'
                    aria-disabled='true'
                  >
                    <span>{tag.name}</span>
                  </Link>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
      <div
        className='bg-primary-opas'
        style={{ backgroundBlendMode: "darken" }}
      >
        <Container fluid='lg' className='wavebackground'>
          <Container fluid className='pt-5'>
            {cardServices?.articlesRelated.map((cardData, index) => {
              return <Card cardData={cardData} key={index} />;
            })}
          </Container>
        </Container>
      </div>
    </Layout>
  );
};
export const Head = ({ data }) => {
  const { title, seo } = data.wpWebproject;
  return (
    <Meta
      title={title}
      metaDesc={seo?.metaDesc}
      metatitle={seo?.title}
      opengraphDescription={seo?.opengraphDescription}
      opengraphUrl={seo?.opengraphUrl}
      opengraphSiteName={seo?.opengraphSiteName}
      opengraphPublisher={seo?.opengraphPublisher}
      opengraphImage={seo?.opengraphImage?.localFile.childImageSharp.resize.src}
      twitterTitle={seo?.twitterTitle}
      twitterDescription={seo?.twitterDescription}
      twitterImage={seo?.twitterImage?.localFile.childImageSharp.resize.src}
    />
  );
};

export const query = graphql`
  query ($portfolioPage: String!) {
    wpWebproject(id: { eq: $portfolioPage }) {
      title
      id
      content
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(sizes: "300", formats: [AUTO, WEBP, AVIF])
            }
          }
          altText
        }
      }

      colorAccent {
        backgroundColor
      }
      cardServices {
        articlesRelated {
          ... on WpPost {
            excerpt
            title
            slug
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData(sizes: "300", formats: [AUTO, WEBP, AVIF])
                  }
                }
                altText
              }
            }
          }
          ... on WpArticle {
            excerpt
            title
            slug
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData(sizes: "300", formats: [AUTO, WEBP, AVIF])
                  }
                }
                altText
              }
            }
          }
          ... on WpWebproject {
            excerpt
            title
            slug
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData(sizes: "300", formats: [AUTO, WEBP, AVIF])
                  }
                }
                altText
              }
            }
          }
        }
      }
      tags {
        nodes {
          name
          link
        }
      }
      webProjects {
        webProjectBriefDescription
        webProjectInstruments
        webProjectLink
        webProjectPlatform
      }
      seo {
        canonical
        metaDesc
        opengraphAuthor
        opengraphDescription
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        title
        opengraphUrl
        readingTime
        opengraphImage {
          localFile {
            childImageSharp {
              resize(
                quality: 90
                height: 630
                width: 1200
                cropFocus: ATTENTION
              ) {
                src
              }
            }
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          localFile {
            childImageSharp {
              resize(
                height: 120
                width: 120
                cropFocus: ATTENTION
                quality: 90
              ) {
                src
              }
            }
          }
        }
      }
    }
  }
`;
export default WebProject;
