import React from "react";
import { useStaticQuery, graphql } from "gatsby";

const Meta = ({
  title,
  metaDesc,
  metatitle,
  opengraphDescription,
  opengraphUrl,
  opengraphSiteName,
  opengraphPublisher,
  opengraphImage,
  twitterTitle,
  twitterDescription,
  twitterImage,
}) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);
  return (
    <>
      <meta name='robots' content='index, follow' />
      <title>{title}</title>
      <meta name='description' content={metaDesc} />
      <meta property='og:locale' content='ru_RU' />
      <meta property='og:type' content='website' />
      <meta property='og:title' content={metatitle} />
      <meta property='og:description' content={opengraphDescription} />
      <meta property='og:url' content={opengraphUrl} />
      <meta property='og:site_name' content={opengraphSiteName} />
      <meta property='article:publisher' content={opengraphPublisher} />
      {opengraphImage && (
        <>
          <meta
            property='og:image'
            content={`${data.site.siteMetadata.siteUrl}${opengraphImage}`}
          />
          <meta property='og:image:width' content='1200' />
          <meta property='og:image:height' content='630' />
          <meta property='og:image:type' content='image/jpeg' />
        </>
      )}
      <meta name='twitter:title' content={twitterTitle} />
      <meta name='twitter:description' content={twitterDescription} />
      {twitterImage && (
        <>
          <meta name='twitter:card' content='summary_large_image' />
          <meta
            name='twitter:image'
            content={`${data.site.siteMetadata.siteUrl}${twitterImage}`}
          />
        </>
      )}
      <meta name='twitter:site' content='@staminaweb' />
    </>
  );
};

export default Meta;
