import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HeaderUnderlined from "../components/HeaderUnderlined";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Meta from "../components/Meta";

const Error404 = ({ data }) => {
  const { title, content, featuredImage, colorAccent } = data.wpPage;
  const thumbnail = getImage(featuredImage?.node.localFile.childImageSharp);
  const bgColor = {
    backgroundColor: `${colorAccent.backgroundColor}`,
  };
  return (
    <Layout>
      <Container fluid='lg'>
        <Row>
          <Col xl={3} lg={3} md={4} sm={3} xs={12} style={bgColor}>
            <div className='shadow-sm bg-secondary'>
              <GatsbyImage
                image={thumbnail}
                alt={featuredImage?.node.altText}
              />
            </div>
          </Col>
          <Col
            xl={9}
            lg={9}
            md={8}
            sm={9}
            xs={12}
            className='p-3 p-md-5 bg-white'
          >
            {" "}
            <HeaderUnderlined title={title} className='fadein' />
            <div
              dangerouslySetInnerHTML={{
                __html: content,
              }}
              className='p-2'
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};
export const Head = ({ data }) => {
  const { title, seo } = data.wpPage;
  return (
    <Meta
      title={title}
      metaDesc={seo?.metaDesc}
      metatitle={seo?.title}
      opengraphDescription={seo?.opengraphDescription}
      opengraphUrl={seo?.opengraphUrl}
      opengraphSiteName={seo?.opengraphSiteName}
      opengraphPublisher={seo?.opengraphPublisher}
      opengraphImage={seo?.opengraphImage?.localFile.childImageSharp.resize.src}
      twitterTitle={seo?.twitterTitle}
      twitterDescription={seo?.twitterDescription}
      twitterImage={seo?.twitterImage?.localFile.childImageSharp.resize.src}
    />
  );
};
export const query = graphql`
  query {
    wpPage(id: { eq: "cG9zdDozMTc=" }) {
      title
      id
      content
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(sizes: "300", formats: [AUTO, WEBP, AVIF])
            }
          }
          altText
        }
      }
      colorAccent {
        backgroundColor
      }

      seo {
        canonical
        metaDesc
        opengraphAuthor
        opengraphDescription
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        title
        opengraphUrl
        readingTime
        opengraphImage {
          localFile {
            childImageSharp {
              resize(
                quality: 90
                height: 630
                width: 1200
                cropFocus: ATTENTION
              ) {
                src
              }
            }
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          localFile {
            childImageSharp {
              resize(
                height: 120
                width: 120
                cropFocus: ATTENTION
                quality: 90
              ) {
                src
              }
            }
          }
        }
      }
    }
  }
`;

export default Error404;
