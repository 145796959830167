import React from "react";
import Layout from "../components/layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import HeaderUnderlined from "../components/HeaderUnderlined";
import { StaticImage } from "gatsby-plugin-image";

const Success = () => {
  return (
    <Layout>
      <Container fluid='lg'>
        <Row>
          <Col
            xl={3}
            lg={3}
            md={4}
            sm={3}
            xs={12}
            style={{ backgroundColor: "#5b5a60a3" }}
          >
            <div className='shadow-sm bg-secondary'>
              <StaticImage
                src='../images/alexander-andrews-HgUDpaGPTEA-unsplash.jpg'
                formats={["AUTO", "WEBP", "AVIF"]}
                loading='eager'
                alt='Photo by Alexander Andrews on Unsplash'
                draggable='false'
                width={500}
              />
            </div>
          </Col>
          <Col
            xl={9}
            lg={9}
            md={8}
            sm={9}
            xs={12}
            className='p-3 p-md-5 bg-white'
          >
            {" "}
            <HeaderUnderlined titlesrc='Сообщение отправлено' />
            <Alert
              variant='warning'
              className='animate__animated animate__pulse animate__slow'
            >
              <Alert.Heading>Спасибо, что связались со мной!</Alert.Heading>

              <p>
                При обработке ваших данных строго соблюдается принцип
                конфиденциальности, поэтому их передача третьим лицам исключена.
                <br />
                Увидимся, услышимся, спишемся!
              </p>
            </Alert>
            <hr />
            <h6>
              Кстати, анекдот на дорожку, который поймет frontend разработчик
            </h6>
            <blockquote>
              Дизайнерский вариант песни Гребенщикова:
              <br />
              Под небом с=100 m=0 y=0 k=0
              <br />
              Есть город c=0 m=15 y=100 k=25
              <br />
              С opacity=0% воротами И Cmd+U,
              <br />
              lightness +100 звездой.
            </blockquote>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};
export const Head = () => {
  return (
    <>
      <title>Сообщение отправлено</title>
    </>
  );
};
export default Success;
