import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../../components/layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HeaderUnderlined from "../../components/HeaderUnderlined";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Meta from "../../components/Meta";
import styled from "styled-components";

const Tags = ({ data }) => {
  const { title, content, featuredImage, colorAccent } = data.wpPage;
  let titleWithTag = `${title}: ${data.wpTag.name}`;
  const thumbnail = getImage(featuredImage?.node.localFile.childImageSharp);
  const bgColor = {
    backgroundColor: `${colorAccent.backgroundColor}`,
  };
  return (
    <Layout>
      <Container fluid='lg'>
        <Row>
          <Col xl={3} lg={3} md={4} sm={3} xs={12} style={bgColor}>
            <div className='shadow-sm bg-secondary'>
              <GatsbyImage
                image={thumbnail}
                alt={featuredImage?.node.altText}
              />
            </div>
          </Col>
          <Col
            xl={9}
            lg={9}
            md={8}
            sm={9}
            xs={12}
            className='p-3 p-md-5 bg-white'
          >
            {" "}
            <HeaderUnderlined title={titleWithTag} className='fadein' />
            <div
              dangerouslySetInnerHTML={{
                __html: content,
              }}
              className='p-2 border-bottom border-secondary border-opacity-25'
            />
            <div>
              <Container>
                <Row>
                  {data.wpTag.posts.nodes.map((post, index) => (
                    <Col
                      xs={12}
                      key={index}
                      className='p-2 border-bottom border-secondary border-opacity-25'
                    >
                      <CardBlock>
                        <CardTitle>
                          <span>{post.title}</span>
                        </CardTitle>

                        <CardArticle
                          dangerouslySetInnerHTML={{
                            __html: post.excerpt,
                          }}
                        />
                        <Link
                          to={`/${post.slug}`}
                          className='text-decoration-none'
                        >
                          <CardFooter>
                            <span className='text-white'>Читать дальше</span>
                          </CardFooter>
                        </Link>
                      </CardBlock>
                    </Col>
                  ))}
                  {data.wpTag.articles.nodes.map((article, index) => (
                    <Col
                      xs={12}
                      key={index}
                      className='p-2 border-bottom border-secondary border-opacity-25'
                    >
                      <CardBlock>
                        <CardTitle>
                          <span>{article.title}</span>
                        </CardTitle>

                        <CardArticle
                          dangerouslySetInnerHTML={{
                            __html: article.excerpt,
                          }}
                        />
                        <Link
                          to={`/${article.slug}`}
                          className='text-decoration-none'
                        >
                          <CardFooter>
                            <span className='text-white'>Читать дальше</span>
                          </CardFooter>
                        </Link>
                      </CardBlock>
                    </Col>
                  ))}
                  {data.wpTag.webprojects.nodes.map((webproject, index) => (
                    <Col
                      xs={12}
                      key={index}
                      className='p-2 border-bottom border-secondary border-opacity-25'
                    >
                      <CardBlock>
                        <CardTitle>
                          <span>{webproject.title}</span>
                        </CardTitle>

                        <CardArticle
                          dangerouslySetInnerHTML={{
                            __html: webproject.excerpt,
                          }}
                        />
                        <Link
                          to={`/${webproject.slug}`}
                          className='text-decoration-none'
                        >
                          <CardFooter>
                            <span className='text-white'>Читать дальше</span>
                          </CardFooter>
                        </Link>
                      </CardBlock>
                    </Col>
                  ))}
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};
export const Head = ({ data }) => {
  const { title, seo } = data.wpPage;
  let titleWithTag = `${title}: ${data.wpTag.name}`;
  return (
    <Meta
      title={titleWithTag}
      metaDesc={seo?.metaDesc}
      metatitle={seo?.title}
      opengraphDescription={seo?.opengraphDescription}
      opengraphUrl={seo?.opengraphUrl}
      opengraphSiteName={seo?.opengraphSiteName}
      opengraphPublisher={seo?.opengraphPublisher}
      opengraphImage={seo?.opengraphImage?.localFile.childImageSharp.resize.src}
      twitterTitle={seo?.twitterTitle}
      twitterDescription={seo?.twitterDescription}
      twitterImage={seo?.twitterImage?.localFile.childImageSharp.resize.src}
    />
  );
};
export const query = graphql`
  query ($id: String!) {
    wpTag(id: { eq: $id }) {
      name
      id
      posts {
        nodes {
          title
          link
          slug
          excerpt
        }
      }
      articles {
        nodes {
          title
          link
          slug
          excerpt
        }
      }
      webprojects {
        nodes {
          title
          link
          slug
          excerpt
        }
      }
    }
    wpPage(id: { eq: "cG9zdDozMTE=" }) {
      title
      id
      content
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(sizes: "300", formats: [AUTO, WEBP, AVIF])
            }
          }
          altText
        }
      }
      colorAccent {
        backgroundColor
      }

      seo {
        canonical
        metaDesc
        opengraphAuthor
        opengraphDescription
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        title
        opengraphUrl
        readingTime
        opengraphImage {
          localFile {
            childImageSharp {
              resize(
                quality: 90
                height: 630
                width: 1200
                cropFocus: ATTENTION
              ) {
                src
              }
            }
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          localFile {
            childImageSharp {
              resize(
                height: 120
                width: 120
                cropFocus: ATTENTION
                quality: 90
              ) {
                src
              }
            }
          }
        }
      }
    }
  }
`;

export default Tags;

const CardBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding-left: 0.5rem;
  padding-bottom: 0.5rem;
`;

const CardTitle = styled.div`
  /* padding: 0.5rem; */
  font-size: 1.25rem;
  font-family: Oswald;
  font-weight: 500;
`;
const CardArticle = styled.article`
  padding-block: 0.5rem;
  font-family: Open Sans, sans-serif;
  font-size: 16px;
`;
const CardFooter = styled.div`
  padding: 0.5rem;
  -webkit-transition: background-color 500ms ease;
  -moz-transition: background-color 500ms ease;
  -o-transition: background-color 500ms ease;
  transition: background-color 500ms ease;
  transition: background-color 0.5s ease;
  background-color: #1e2b37;
  font-family: Oswald;
  font-weight: 500;
  &:hover {
    -webkit-transition: background-color 500ms ease;
    -moz-transition: background-color 500ms ease;
    -o-transition: background-color 500ms ease;
    transition: background-color 500ms ease;
    background-color: #344352;
  }
`;
