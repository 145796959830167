import React from "react";
import Layout from "../components/layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HeaderUnderlined from "../components/HeaderUnderlined";
import ContactForm from "../components/ContactForm";
import { StaticImage } from "gatsby-plugin-image";

const Contacts = () => {
  return (
    <Layout>
      <Container fluid='lg' id='contact-block'>
        <Row>
          <Col
            xl={3}
            lg={3}
            md={4}
            sm={3}
            xs={12}
            style={{ backgroundColor: "#a08285b3" }}
          >
            <div className='shadow-sm bg-secondary'>
              <StaticImage
                src='../images/alexander-andrews-HgUDpaGPTEA-unsplash.jpg'
                formats={["AUTO", "WEBP", "AVIF"]}
                loading='eager'
                alt='Photo by Alexander Andrews on Unsplash'
                draggable='false'
                width={500}
              />
            </div>
          </Col>
          <Col
            xl={9}
            lg={9}
            md={8}
            sm={9}
            xs={12}
            className='p-3 p-md-5 bg-white'
          >
            {" "}
            <HeaderUnderlined title='Контакты' className='fadein' />
            <ContactForm />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};
export const Head = () => {
  return (
    <>
      <title>Контакты</title>
    </>
  );
};
export default Contacts;
