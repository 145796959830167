import React from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "gatsby";

const Pagination = ({ paginationData }) => {
  return (
    <Container className='py-3'>
      <Row>
        <Col>
          {paginationData.previous && (
            <Link
              to={`/${paginationData.previous.slug}`}
              rel='prev'
              className='fw-bold text-decoration-none fs-5'
            >
              <FaArrowLeft className='react-icons' />{" "}
              {paginationData.previous.title}
            </Link>
          )}
        </Col>
        <Col className='pagination-right'>
          {paginationData.next && (
            <Link
              to={`/${paginationData.next.slug}`}
              rel='next'
              className='fw-bold text-decoration-none fs-5'
            >
              {paginationData.next.title}{" "}
              <FaArrowRight className='react-icons' />
            </Link>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Pagination;
