import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Link, navigate } from "gatsby";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";

const ContactForm = () => {
  const formik = useFormik({
    initialValues: {
      namesender: "",
      email: "",
      message: "",
      terms: "",
      honeypot: "false",
    },
    validationSchema: Yup.object().shape({
      namesender: Yup.string()
        .strict(true)
        // .trim("Не включайте начальные и конечные пробелы")
        .matches(
          /^[a-zA-Za-яА-Я ]+$/,
          "Не используйте цифры и специальные знаки"
        )
        .min(4, "Слишком короткое имя")
        .max(12, "Слишком длинное имя")
        .required("Это поле обязательно"),
      email: Yup.string()
        .email("Неверный адрес электронной почты")
        .required("Это поле обязательно"),
      message: Yup.string()
        .strict(true)
        // .trim("Не пишите пробелы в начале и в конце")
        .min(10, "Не менее 10 символов")
        .max(300, "Не более 300 символов")
        .required("Это поле обязательно"),
      terms: Yup.bool()
        .oneOf([true], "Условия политики ОПД должны быть приняты")
        .required("Это поле обязательно"),
      honeypot: Yup.bool().oneOf([false], "You shall not pass, bot!"),
    }),
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));

      axios({
        method: "post",
        url: "https://31350.ru/wp-json/contact-form-7/v1/contact-forms/297/feedback",
        data: {
          namesender: values.namesender.trim(),
          email: values.email,
          message: values.message.trim(),
          terms: values.terms,
          honeypot: values.honeypot,
        },
        headers: {
          "Content-Type": "multipart/form-data",
        },
        responseType: "application/json",
      })
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
      navigate("/success/");
      formik.resetForm();
    },
  });

  return (
    <>
      <h4 className='pb-3'>Заинтересовал? Свяжитесь со мной!</h4>
      <p>
        Вы можете оправить мне сообщение через{" "}
        <a
          href='https://t.me/AndreyBond'
          target='_blank'
          rel='noopener noreferrer'
        >
          <b>Telegram</b>
        </a>{" "}
        или найти меня на сайте{" "}
        <a
          href='https://profi.ru/profile/BondarenkoAA146'
          target='_blank'
          rel='noopener noreferrer'
        >
          <b>Профи.ру</b>
        </a>
        .<br />
        Если у Вас есть вопросы или предложения, я с радостью готов ответить на
        них.
        <br />
        Заполните <b>контактную форму</b> ниже и я обязательно свяжусь с вами в
        ближайшее время.
      </p>
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Form.Group controlId='validationFormik01'>
          <Form.Label className='fw-bold pt-5' name='namesender'>
            Ваше имя:
            {formik.touched.namesender && formik.errors.namesender ? (
              <small className='text-danger p-2'>
                {formik.errors.namesender}
              </small>
            ) : null}
          </Form.Label>
          <Form.Control
            type='text'
            placeholder='Ваше имя без пробелов в начале и в конце'
            {...formik.getFieldProps("namesender")}
          />
        </Form.Group>

        <Form.Group controlId='validationFormikEmail'>
          <Form.Label className='fw-bold p-2' name='email'>
            Email:
            {formik.touched.email && formik.errors.email ? (
              <small className='text-danger p-2'>{formik.errors.email}</small>
            ) : null}
          </Form.Label>
          <Form.Control
            type='email'
            placeholder='Электронный адрес'
            {...formik.getFieldProps("email")}
          />
        </Form.Group>

        <Form.Group controlId='validationFormik03'>
          <Form.Label className='fw-bold p-2' name='message'>
            Сообщение:
            {formik.touched.message && formik.errors.message ? (
              <small className='text-danger p-2'>{formik.errors.message}</small>
            ) : null}
          </Form.Label>
          <Form.Control
            as='textarea'
            rows='3'
            {...formik.getFieldProps("message")}
          />
        </Form.Group>
        <div className='mb-3 pt-3'>
          <Form.Group>
            <Form.Check
              label='Разрешаю обработку персональных данных'
              type='checkbox'
              {...formik.getFieldProps("terms")}
            />
            {formik.touched.terms && formik.errors.terms ? (
              <small className='text-danger pl-2'>{formik.errors.terms}</small>
            ) : null}
          </Form.Group>
          <Form.Group className='ohnohoneybot'>
            <Form.Check
              tabIndex='-10'
              label='Сладкий мед для ботов'
              type='checkbox'
              {...formik.getFieldProps("honeypot")}
            />
            {formik.touched.honeypot && formik.errors.honeypot ? (
              <small className='text-danger pl-2'>
                {formik.errors.honeypot}
              </small>
            ) : null}
          </Form.Group>

          <Button variant='secondary' type='submit' className='mt-3'>
            Отправить
          </Button>
        </div>
        <Link to='/privacy' className='fw-bold '>
          <small>
            Политика в отношении обработки персональных данных (ОПД)
          </small>
        </Link>
      </Form>
    </>
  );
};
export default ContactForm;
