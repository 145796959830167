import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HeaderUnderlined from "../components/HeaderUnderlined";

const IndexPage = ({ data }) => {
  const { title, content } = data.allWpPage.nodes[0];
  // const cardsData = relationships.field_service;
  return (
    <Layout>
      <Container fluid='lg'>
        <Row>
          <Col className='p-3 p-md-5 bg-white'>
            <HeaderUnderlined title={title} className='fadein' />
            <div
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export const Head = ({ data }) => {
  const { title } = data.allWpPage.nodes[0];
  return (
    <>
      <title>{title}</title>
    </>
  );
};

export const query = graphql`
  query {
    allWpPage(filter: { isPrivacyPage: { eq: true } }) {
      nodes {
        title
        content
      }
    }
  }
`;
export default IndexPage;
